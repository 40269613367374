<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div class="content-form">
            <v-tabs-items v-model="tabWarehouse">
              <v-tab-item>
                <warehouse-detail-content-tab-one-layout
                  :texts="texts"
                  :arr="arr"
                />
              </v-tab-item>
              <v-tab-item>
                <warehouse-detail-content-tab-two-layout
                  :texts="texts"
                  :arr="arr"
                />
              </v-tab-item>
              <v-tab-item>
                <warehouse-detail-content-tab-three-layout
                  :texts="texts"
                  :arr="arr"
                />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
    },
    arr: {
      type: Object,
    },
  },
  name: "WarehouseDetailContentLayout",
  data() {
    return {
      //VARIABLES
      tab:0
    };
  },
  computed: {
    tabWarehouse: function () {
      return this.$store.state.tabWarehouse;
    },
  },
};
</script>

<style scoped>
.v-tabs-items {
  background-color: #f2f2f4;
}
</style>